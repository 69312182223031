<template>
  <div>
    <h3>
      {{ $t('REPORT_AREAS_QUESTION') }}
      <span class="required-field">({{ $t('report_required_field') }})</span>
    </h3>
    <h4>{{ $t('REPORT_AREAS_TIP') }}</h4>

    <div class="mt-15">
      <div
        v-for="(item, idx) in areas"
        :key="idx"
        class="report-form__item--input autocomplete"
      >
        <input
          :value="item[key]"
          type="text"
          name="area"
          :placeholder="$t('type_here_area')"
          autocomplete="off"
          disabled
        />
        <span class="del-btn" @click="removeField(idx)">x</span>
      </div>
    </div>

    <slot />

    <Autocomplete
      v-if="!hasCustomArea"
      v-model="search"
      :options="options"
      value-key="id"
      :show-all-options="true"
      :placeholder="$t('type_here_area')"
      @select="onSelect"
    >
      <template slot="autocomplete-label" slot-scope="{ item }">
        {{ item[key] }}
      </template>
    </Autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Autocomplete: () => import('../Autocomplete.vue'),
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    hasCustomArea: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      areas: this.value,
      allAreas: [],
      options: [],
    }
  },

  computed: {
    ...mapState(['locale']),

    key() {
      return this.locale?.substring(0, 2)
    },

    selectedAreaIds() {
      return this.areas.map(({ id }) => id)
    },

    avalaibleAreas() {
      return this.allAreas.filter(
        ({ id }) => !this.selectedAreaIds.includes(id),
      )
    },
  },

  watch: {
    search() {
      this.searchAreas(this.search)
    },

    areas() {
      this.$emit('input', this.selectedAreaIds)
    },
  },

  async created() {
    this.allAreas = await this.getAreas()
    this.options = this.allAreas
  },

  methods: {
    ...mapActions(['getAreas']),

    removeField(field) {
      this.areas.splice(field, 1)
    },

    searchAreas(searchTerm) {
      const term = searchTerm?.trim()?.toLowerCase()
      const avalaibleAreas = this.allAreas.filter(
        ({ id }) => !this.selectedAreaIds.includes(id),
      )

      if (!term) {
        this.options = avalaibleAreas
      }

      const filteredAreas = avalaibleAreas.filter((area) => {
        return area[this.key].trim().toLowerCase().includes(term)
      })

      this.options = [...filteredAreas, term]
    },

    onSelect(selected) {
      this.areas.push(selected)
      this.options = this.avalaibleAreas
    },
  },
}
</script>

<style scoped>
.del-btn {
  top: 15px;
}
</style>
